<template>
  <div class="basket" :class="{'open':basketState}">
    <div class="basket-header basket-header d-flex flex-row justify-between">
      <h3 class="title">Shopping cart</h3>
      <img @click="closeBasket()" style="cursor: pointer" src="@/assets/img/Modal/Close.svg" alt="">
    </div>
    <div class="basket-body">
      <div class="mobile">
        <swipe-list
            ref="list"
            class="card"
            :disabled="!enabled"
            :items="receiveBasket.product"
            item-key="id"
        >
          <template v-slot="{item}">
            <div class="">
              <div class="list__item">
                <div class="item-container">
                  <div class="item-header d-flex flex-row justify-between">
                    <div class="left d-flex">
                      <div class="item-images">
                        <img :src="item.images[0].url" alt="">
                      </div>
                      <div class="item-description">
                        <h4 class="title">{{ item.title }}</h4>
                        <h6 class="type">Single roses</h6>
                        <span class="price"><h5>€ {{ (item.price/100)*item.counter }}</h5><span>{{ item.counter }} roses</span></span>
                      </div>
                    </div>
                    <div class="right">
                      <img class="trash" @click="deleteItem(item.customId)" style="cursor: pointer" src="@/assets/img/Basket/Trash.svg" alt="">
                      <div class="product-counter">
                        <div class="minus" @click="decreaseCounter(item)"><img src="@/assets/img/SelectProduct/minus.svg" alt=""></div>
                        <div class="counter">{{ item.counter }}</div>
                        <div class="plus" @click="increaseCounter(item)"><img src="@/assets/img/SelectProduct/plus.svg" alt=""></div>
                      </div>
                    </div>
                  </div>
                  <ul class="additions-list">
                    <li class="additions-list__item d-flex flex-row justify-between">
                      <div class="d-flex flex-row align-center">
                        <div class="icon-container">
                          <img src="@/assets/img/SelectProduct/User.svg" alt="">
                        </div>
                        <h6 class="title"> {{ item.tav !== null ? `${item.tav.fname} ${item.tav.lname}` : 'TAV' }}</h6>
                      </div>
                      <div class="d-flex flex-row align-center">
                        <h6 class="price">Free</h6>
                        <h6 class="change" @click="openModal(item.customId)">{{ item.tav !== null ? 'Edit' : 'Add' }}</h6>
                      </div>
                    </li>
                    <li class="additions-list__item d-flex flex-row justify-between">
                      <div class="d-flex flex-row align-center">
                        <div class="icon-container">
                          <img src="@/assets/img/SelectProduct/Pen.svg" alt="">
                        </div>
                        <h6 class="title">{{ item.card !== null ? `${item.card.title}` : 'Write personal message' }}</h6>
                      </div>
                      <div class="d-flex flex-row align-center">
                        <h6 class="price">€ {{item.card !== null ? `${item.card.price/100}` : '0.99'}} </h6>
                        <h6 class="change" @click="transitionToCards(item.id,item.customId)">{{ item.card !== null ? 'Edit' : 'Add' }}</h6>
                      </div>
                    </li>
                    <li class="additions-list__item d-flex flex-row justify-between">
                      <div class="d-flex flex-row align-center">
                        <div class="icon-container">
                          <img src="@/assets/img/SelectProduct/gift.svg" alt="">
                        </div>
                        <h6 class="title">{{ item.gift !== null ? `${item.gift.title}` : 'Add teddy bear, chocolate, ect.' }}</h6>
                      </div>
                      <div class="d-flex flex-row align-center">
                        <h6 class="price">{{ item.gift !== null ? `€ ${item.gift.price/100}` : 'From € 8.99' }} </h6>
                        <h6 class="change" @click="transitionToGifts(item.id,item.customId)">{{ item.gift !== null ? 'Edit' :'Add' }}</h6>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </template>
          <template v-slot:right="{ item, close }">
            <div class="swipeout-action red" title="remove" @click="deleteItem(item.customId)">
              <img src="@/assets/icons/Trash.svg">
            </div>
          </template>
        </swipe-list>
      </div>
      <div class="desktop">
        <ul class="list" v-if="receiveBasket.product.length !== 0">
          <li class="list__item" :id="`m${index}`" v-for="(item,index) in receiveBasket.product" :key="item.customId">
            <div class="item-container">
              <div class="item-header d-flex flex-row justify-between">
                <div class="left d-flex">
                  <div class="item-images">
                    <img :src="item.images[0].url" alt="">
                  </div>
                  <div class="item-description">
                    <h4 class="title">{{ item.title }}</h4>
                    <h6 class="type">Single roses</h6>
                    <span class="price"><h5>€ {{ (item.price/100)*item.counter }}</h5><span>{{ item.counter }} roses</span></span>
                  </div>
                </div>
                <div class="right">
                  <img class="trash" @click="deleteItem(item.customId)" style="cursor: pointer" src="@/assets/img/Basket/Trash.svg" alt="">
                  <div class="product-counter">
                    <div class="minus" @click="decreaseCounter(item)"><img src="@/assets/img/SelectProduct/minus.svg" alt=""></div>
                    <div class="counter">{{ item.counter }}</div>
                    <div class="plus" @click="increaseCounter(item)"><img src="@/assets/img/SelectProduct/plus.svg" alt=""></div>
                  </div>
                </div>
              </div>
              <ul class="additions-list">
                <li class="additions-list__item d-flex flex-row justify-between">
                  <div class="d-flex flex-row align-center">
                    <div class="icon-container">
                      <img src="@/assets/img/SelectProduct/User.svg" alt="">
                    </div>
                    <h6 class="title"> {{ item.tav !== null ? `${item.tav.fname} ${item.tav.lname}` : 'TAV' }}</h6>
                  </div>
                  <div class="d-flex flex-row align-center">
                    <h6 class="price">Free</h6>
                    <h6 class="change" @click="openModal(item.customId)">{{ item.tav !== null ? 'Edit' : 'Add' }}</h6>
                  </div>
                </li>
                <li class="additions-list__item d-flex flex-row justify-between">
                  <div class="d-flex flex-row align-center">
                    <div class="icon-container">
                      <img src="@/assets/img/SelectProduct/Pen.svg" alt="">
                    </div>
                    <h6 class="title">{{ item.card !== null ? `${item.card.title}` : 'Write personal message' }}</h6>
                  </div>
                  <div class="d-flex flex-row align-center">
                    <h6 class="price">€ {{item.card !== null ? `${item.card.price/100}` : '0.99'}} </h6>
                    <h6 class="change" @click="transitionToCards(item.id,item.customId)">{{ item.card !== null ? 'Edit' : 'Add' }}</h6>
                  </div>
                </li>
                <li class="additions-list__item d-flex flex-row justify-between">
                  <div class="d-flex flex-row align-center">
                    <div class="icon-container">
                      <img src="@/assets/img/SelectProduct/gift.svg" alt="">
                    </div>
                    <h6 class="title">{{ item.gift !== null ? `${item.gift.title}` : 'Add teddy bear, chocolate, ect.' }}</h6>
                  </div>
                  <div class="d-flex flex-row align-center">
                    <h6 class="price">{{ item.gift !== null ? `€ ${item.gift.price/100}` : 'From € 8.99' }} </h6>
                    <h6 class="change" @click="transitionToGifts(item.id,item.customId)">{{ item.gift !== null ? 'Edit' :'Add' }}</h6>
                  </div>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div style="padding: 24px">
      <div class="basket-tile" :style="{backgroundImage:`url(${bg})`}">
        <div class="d-flex flex-column align-center">
          <h6 class="title">Deliver today? You still have:</h6>
          <span class="basket-time">02 : 34 : 56</span>
        </div>
      </div>
    </div>
    <div class="basket-footer">
      <div class="d-flex flex-row justify-between">
        <h6 class="">Subtotal</h6>
        <h6 style="color:#8183FC;">€ {{ receiveBasket.totalPrice / 100 }}</h6>
      </div>
      <div>
        <h6 style="color:#AAAAAA;padding-top: 5px" class="">Shipping and taxes calculated at checkout</h6>
      </div>
      <div class="d-flex flex-row justify-center" style="padding-top: 30px">
        <button class="btn close" @click="transitionToCheckout">Checkout</button>
      </div>
    </div>
    <TavModal :customId="customId" ref="tav" />
  </div>
</template>

<script>
import bg from '@/assets/img/Basket/Elements.svg';
import { SwipeList, SwipeOut } from 'vue-swipe-actions';
import 'vue-swipe-actions/dist/vue-swipe-actions.css';
export default {
  name:"Basket",
  props:{
    basketState:{
      type:Boolean,
      default:false
    },
    closeBasket:{
      type:Function
    }
  },
  data() {
    return {
      bg,
      customId:12342312341,
      enabled: true,
    }
  },
  components:{
    TavModal:()=> import('@/components/Modals/TAVModal/TavModal.vue'),
    SwipeList,
    SwipeOut
  },
  methods: {
    increaseCounter(elem){
      elem.counter++
    },
    decreaseCounter(elem){
      if(elem.counter > 1){
        elem.counter--
      }
    },
    deleteItem(id){
      this.$store.dispatch('deleteItem',id)
    },
    openModal(id){
      this.customId = id;
      this.$refs.tav.open();
    },
    transitionToGifts(id,customId){
      this.$router.push(`/selectProduct/&${id}/gifts`);
      this.$store.dispatch('addSelectCustomId',customId);
    },
    transitionToCards(id,customId){
      this.$router.push(`/selectProduct/&${id}/cards`);
      this.$store.dispatch('addSelectCustomId',customId)
    },
    transitionToCheckout(){
      this.$router.push('/checkout')
    }
  },
  computed:{
    receiveBasket() {
      const totalPrice = this.$store.getters['receiveBasket'].reduce((accum,current)=> accum + current.price,0);
      return {product:this.$store.getters['receiveBasket'],totalPrice};
    }
  },
  mounted() {

  }
}
</script>

<style lang="scss" scoped>
@import "Basket";
.swipeout-action {
  background: #D6585E;
  margin: 10px -6px 10px 0;
  display: flex;
  align-items: center;
  border-radius: 0px 12px 12px 0px;
  cursor: pointer;
  img{
    padding: 20px;
  }
}

</style>